<template>
  <div class="icon-bg">
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.3"
        d="M10.917 17C10.917 16.4477 10.4695 16 9.91746 16C9.36541 16 8.91789 16.4477 8.91789 17V18C8.91789 20.2091 10.708 22 12.9162 22L18.9127 22C21.1209 22 22.9109 20.2091 22.9109 18L22.9109 6C22.9109 3.79086 21.1209 2 18.9127 2L12.9092 2C10.701 2 8.91095 3.79086 8.91095 6L8.91095 7C8.91095 7.55229 9.35847 8 9.91052 8C10.4626 8 10.9101 7.55229 10.9101 7V6C10.9101 4.89543 11.8051 4 12.9092 4L18.9127 4C20.0168 4 20.9118 4.89543 20.9118 6L20.9118 18C20.9118 19.1046 20.0168 20 18.9127 20L12.9162 20C11.8121 20 10.917 19.1046 10.917 18V17Z"
        fill="#A7A9C0"
      />
      <rect
        opacity="0.3"
        x="4.91791"
        y="13"
        width="2"
        height="12"
        rx="1"
        transform="rotate(-90 4.91791 13)"
        fill="#A7A9C0"
      />
      <path
        d="M7.62501 14.2929C8.01554 14.6834 8.01554 15.3166 7.62501 15.7071C7.23449 16.0976 6.60133 16.0976 6.2108 15.7071L3.2108 12.7071C2.82028 12.3166 2.82028 11.6834 3.2108 11.2929L6.2108 8.29289C6.60133 7.90237 7.23449 7.90237 7.62501 8.29289C8.01554 8.68342 8.01554 9.31658 7.62501 9.70711L5.33212 12L7.62501 14.2929Z"
        fill="#A7A9C0"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconExit",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.icon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  flex-shrink: 0;
  transition: 0.1s;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
  }
  @media (hover: hover) {
    &:hover {
      background: #ebf2fa;
      svg {
        path {
          fill: #5551f9;
        }
      }
    }
  }
  &:active {
    background: transparent;
    svg {
      path {
        fill: #5551f9;
      }
    }
  }
}
</style>
